/* scss/molecules/_filterRow.scss */

.filterRow{
    margin-bottom: $base*2;
    .filterRow__item{
        @include col($four);
        padding: 0 10px;
    }
}

@media only screen and ( max-width: 737px ){
    .filterRow{
        margin-bottom: $base*2;
        .filterRow__item{
            @include col($twelve);
            padding: 0;
            margin-bottom: $base/2;
        }
    }
}