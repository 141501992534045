/*scss/atoms/_form-elements.scss*/


.formGroup{
  margin-bottom:$base; 
  position:relative;
}
.formInput{
	height: 50px;
	border-radius:0;
	padding-left:10px;
	width:100%;
	//background:#fff;
    line-height: 50px;
    border: 1px solid $bodyColor;
    padding-top:0;
    transition: all 0.1s linear;
  
  &:focus{
    border:none; 
  }
}
textarea.formInput{
	height:160px;
  margin-bottom: -5px;
  line-height: normal;
}

.formLabel{
  font-weight:bold;
  text-transform:uppercase;
  margin-bottom:0;
  font-family:$font2;
}

.formGroup-check{
  label{
    font-weight:normal;
    font-family:$font1; 
    margin-bottom: 0; 
  }
}

.formInput-check {
    display:none;
}
.formInput-check + label span {
    display:inline-block;
    width:14px;
    height:14px;
    margin:-1px 4px 0 0;
    vertical-align:middle;
    background:#fff;
    border:1px solid #222222;
    cursor:pointer;
    position:relative;
    top:-2px;
}
.formInput-check:checked + label span {
    background:#222222;
}



::-webkit-input-placeholder {
   color: #000;
}

:-moz-placeholder { /* Firefox 18- */
   color: #000;  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #000;  
}

:-ms-input-placeholder {  
   color: #000; 
}


.js-floatLabel {
  position:absolute;
  top:10px;
  left: 8px;
  font-size:12px;
  color:#aaa;
  transition: all 0.1s linear;
  opacity:0;
  font-weight: bold;
}
.js-floatLabel.on {
  color:#4481C4;
  
}
.js-floatLabel.show {
  position:absolute;
  top:2px;
  left: 8px;
  opacity:1;
  //background-color: red;
  width: 100%;
  
}

.labelOn .formInput{
  padding-top:10px; 
}
body {
  /* the following line fixes a blink in chrome https://code.google.com/p/chromium/issues/detail?id=108025 */
  -webkit-backface-visibility: hidden;
}




.selectize-input{
  overflow:inherit; 
  padding: 14px 8px;
  border: none;
  border-radius: 0;
  box-shadow: none;
}
.selectize-dropdown, .selectize-input, .selectize-input input{
  font-family:$font2; 
  font-size:15px;
  color:#35363a;
  font-weight:bold;
  text-transform:uppercase;
}

.selectBox{
  cursor:pointer;
  .option.active{
    background:none; 
    color:#35363a;
  }
  .option:hover{
    background:none; 
    color:$color1;
  }
}

.selectize-control.single .selectize-input:after{
  content:"";
  border:none;
  height:10px;
  width:21px;
  background-image:url(../img/chev-down.png);
  background-position:right center;
  background-repeat:no-repeat;
}

