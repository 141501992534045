/* scss/molecules/_iconInput.scss */

.iconInput__icon{
    float: right;
    background: $bodyColor;
    color: #fff;
    border: none;
    height: 30px;
}


.iconInput__input{
    overflow: hidden; 
    input{
        width: 100%;
        height: 30px;
        padding-left: 10px;
    }
    
}
.iconInput__clear{
    background: none;
    border: none;
    display: block;
    width: 100%;
    text-align: right;
    color: $color1;
    text-decoration: underline;
    padding: 0;
    margin-top: 5px;
}

.iconInput{
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #ccc;
    }
    ::-moz-placeholder { /* Firefox 19+ */
    color: #ccc;
    }
    :-ms-input-placeholder { /* IE 10+ */
    color: #ccc;
    }
    :-moz-placeholder { /* Firefox 18- */
    color: #ccc;
}
}