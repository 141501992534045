/* scss/organisms/_footerMain.scss */

.footerMain{
    background: $color2;
    padding: 50px 0;
}

.legal{
    color: #e8e7df;
    font-size: 14px;
    margin-bottom: 0;
}