/* scss/organisms/_headerMain.scss */

.headerMain{
    background: rgba(255, 255, 255, 0.9);
    padding: 20px 0;
    position: absolute;
    top: 0;
    width: 100%;
}
.headerMain__inner{
    @extend .wrap;
    @extend .grid-row;
}
.headerMain__left{
    @include col($four);
}
.headerMain__right{
    @include col($eight);
    .navMain{
        float: right;
        margin-top: 33px;
        margin-bottom: 0;
    }
}

@media only screen and ( max-width: 702px ){
    .headerMain__left{
        @include col($twelve);
        margin-bottom: $base/2;
        text-align: center;
    }
    .headerMain__right{
        @include col($twelve);
        text-align: center;
        .navMain{
            float: none;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}