/*scss/variables/_colors.scss*/

$color1: #ee1c25;
$color2: #3c413a;
$color3: #5c897c;
$bodyColor: #333333;

.color1 {
  background: $color1;
}

.color2 {
  background: $color2;
}

.color3 {
  background: $color3;
}


.colors {
  @extend .grid-row;
}

.color {
  width: 100px;
  height: 100px;
  float: left;
  margin-right: 10px;
  padding: 10px;
  color: #fff;
  font-size: 12px;
}

.colorFixed {
  position: fixed;
  z-index: 3000;
  right: 0;
  top: 0;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.57);
  .color {
    float: none;
    margin-right: 0;
    width: 70px;
    height: 50px;
  }
}