/* scss/organisms/_itemGrid.scss */

.itemGrid{
	@extend .grid-row;
	@include block-grid(5);
	.itemGrid__item{
		@extend .block-grid-item;
		position:relative;
	}

	@media (max-width: 715px){ 
		@include block-grid(3);
	}
	@media (max-width: 427px){ 
		@include block-grid(2);
	}
}