/* scss/organisms/_banner.scss */

.banner{
    height: 0;
    padding-bottom: 25%;
    background-image: url('../img/banner.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left 0;
    min-height: 300px;
}



