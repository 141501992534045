.h1, h1, h2, .h2, .h3, h3, .h4, h4, .h5, h5, .h6, h6{
    margin-top:0;
    margin-bottom: $base;
    line-height: 1;
}

.h1{
  font-family: $font1;
  font-size: 36px;
  font-weight: normal;
}
.h2{
  font-size:24px;
  font-family: $font2;
  
}
.h3{
  font-size:40px;
  font-family:$font1;
  color:#35363a;
  font-weight:normal;
  font-style:italic;
  text-transform:none;
}


.h4{

}
.h5{

}