/* scss/molecules/_imgBlock.scss */

.imgBlock{
    display: block;
    text-align: center;
    color: $color1;
    &:hover{
        color: $color1;
    }
    span{
        display: block;
        font-weight: bold;
    }
}
.imgBlock__linkGroup{
    margin-top: 6px;
}