/* scss/molecules/_catList.scss */

.catList__list{
    list-style: none;
    padding: 0;
    a{
        display: block;
        color: $bodyColor;
        margin-bottom: $base/2;
    }
}