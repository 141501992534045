/* scss/molecules/_navMain.scss */

.navMain{
    display: inline-block;
    li{
        display: inline-block;
    }
    a{
        display: block;
        font-size: 15px;
        color: $bodyColor;
        padding: 5px 20px;
        font-weight: bold;
    }
}
