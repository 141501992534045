/* scss/organisms/_content.scss */

.content__left{
    @include col($two);
    padding-right: 25px;
}
.content__right{
    @include col($ten);
    border-left: 1px solid $bodyColor;
    padding-left: 60px;
}

@media only screen and ( max-width: 737px ){
    .content__left{
        @include col($twelve);
        padding-right: 0;
        margin-bottom: $base;
    }
    .content__right{
        @include col($twelve);
        border-left: none;
        padding-left: 0;
    }
}