/* scss/organisms/_introRow.scss */

.introRow{
    margin-bottom: $base*2;
}

.introRow__left{
    @include col($eight);
}
.introRow__right{
    @include col($four);
    padding-left: 50px;
    .searchForm{
        margin-top: 50px;
    }
}

@media only screen and ( max-width: 724px ){
    .introRow__left{
        @include col($twelve);
    }
    .introRow__right{
        @include col($twelve);
        padding-left: 0;
        .searchForm{
            margin-top: 0;
        }
    }
}