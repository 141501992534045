.btns{
    display:inline-block;
    
    padding: 10px 30px;
    color: #333;
    border: 2px solid;
    opacity:1;
    cursor:pointer;
    transition: all .25s;
    position:relative;
    text-transform:uppercase;
    font-size:14px;
    font-weight: bold;
    text-align:center;
}
.btns:hover{

	text-decoration:none;
}
.btn1{
	
}
.btn2{
	border: 1px solid;
	display: block;
}

.btn3{
	background:#414141;
}
