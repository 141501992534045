/*scss/variables/_fonts.scss*/

$font1: 'Arimo', sans-serif;
$font2: 'Anton', sans-serif;


.font1{
	font-family: $font1;
}
.font2{
	font-family: $font2;
}

.font{
	span{
		display:block;
	}
}